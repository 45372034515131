import { graphql, useStaticQuery } from 'gatsby';
import * as productTransformers from '@content/transformers/ProductPageTransformers';
import { ProductPage } from '@content/types/product';

const ProductQuery = (): ProductPage => {
  const productData = useStaticQuery(graphql`
    query {
      allSiteSettingEntityProduct {
        edges {
          node {
            langcode
            field_hero_button_text
            field_hero_video
            field_hero_headline {
              value
            }
            field_hero_image {
              alt
            }
            field_hero_image_mobile {
              alt
            }
            field_hero_text {
              value
            }
            field_hero_video_thumbnail {
              alt
            }
            field_kasa_online
            field_porownaj_taryfy_w_cenniku
            field_settings_text_2
            field_settings_text_6
            field_settings_text_7
            field_meta_title
            field_meta_description
            relationships {
              field_main_image {
                localFile {
                  publicURL
                }
              }
              field_hero_image {
                localFile {
                  childImageSharp {
                    fixed(width: 1600, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              field_hero_image_mobile {
                localFile {
                  childImageSharp {
                    fixed(width: 800, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              field_hero_video_thumbnail {
                localFile {
                  childImageSharp {
                    fixed(width: 900, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              field_components {
                ... on paragraph__sidebar_image {
                  field_title
                  relationships {
                    field_sidebar_image_items {
                      field_label_1
                      field_text_1 {
                        value
                      }
                      field_image_side
                      field_image_1 {
                        alt
                      }
                      relationships {
                        field_image_1 {
                          localFile {
                            childImageSharp {
                              fixed(width: 540, quality: 100) {
                                ...GatsbyImageSharpFixed
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__branches_saving_up_block {
                  field_branches_button_text
                  field_branches_text {
                    value
                  }
                  field_paragraph_headline {
                    value
                  }
                }
                ... on paragraph__branches_tariff {
                  field_branches_name
                  field_recommended
                  relationships {
                    field_branches_profits {
                      field_profits_active
                      field_profits_name {
                        value
                      }
                    }
                    field_date_from_api {
                      iposfee
                      field_pl_locationfee
                    }
                  }
                }
                ... on paragraph__bullets_container {
                  relationships {
                    field_bullets_item {
                      field_image {
                        alt
                      }
                      field_text {
                        value
                      }
                      field_bullets_icons {
                        alt
                      }
                      field_nc_button_text
                      field_btn_url
                      field_video
                      relationships {
                        field_image {
                          localFile {
                            childImageSharp {
                              fluid(maxWidth: 475, quality: 100) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                          }
                        }
                        field_bullets_icons {
                          localFile {
                            publicURL
                            childImageSharp {
                              fluid(maxWidth: 475, quality: 100) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__terminal_models {
                  field_terminals_title {
                    value
                  }
                  field_terminals_description {
                    value
                  }
                  relationships {
                    field_terminals_items {
                      field_terminal_band_text
                      field_button_text
                      field_terminal_description {
                        value
                      }
                      field_terminal_image {
                        alt
                      }
                      relationships {
                        field_terminal_image {
                          localFile {
                            childImageSharp {
                              fluid(maxWidth: 475, quality: 100) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__ico_and_text {
                  field_text {
                    value
                  }
                  field_ico {
                    alt
                  }
                  relationships {
                    field_ico {
                      localFile {
                        publicURL
                        childImageSharp {
                          fixed(width: 250, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__porownanie_terminali {
                  field_description {
                    value
                  }
                  field_image {
                    alt
                  }
                  field_image_1 {
                    alt
                  }
                  field_kup_teraz
                  field_label_1
                  field_label_2
                  field_label_3
                  field_label_4
                  field_paragraph_headline {
                    value
                  }
                  field_text_1 {
                    value
                  }
                  field_text_2 {
                    value
                  }
                  field_text_3
                  field_text_4 {
                    value
                  }
                  field_zobacz_szczegoly
                  relationships {
                    field_image {
                      localFile {
                        childImageSharp {
                          fixed(width: 450, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    field_image_1 {
                      localFile {
                        childImageSharp {
                          fixed(width: 450, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__text_link {
                  field_nc_button_text
                  field_paragraph_headline {
                    value
                  }
                }
                ... on paragraph__zdjecie_opis_link {
                  field_image {
                    alt
                  }
                  field_nc_button_text
                  field_paragraph_headline {
                    value
                  }
                  field_text {
                    value
                  }
                  field_hidden_or_disable
                  relationships {
                    field_image {
                      localFile {
                        childImageSharp {
                          fixed(width: 300, quality: 100, fit: CONTAIN) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__zdjecie_opis_link_ico {
                  field_image {
                    alt
                  }
                  field_image_1 {
                    alt
                  }
                  field_nc_button_text
                  field_paragraph_headline {
                    value
                  }
                  field_text {
                    value
                  }
                  relationships {
                    field_image {
                      localFile {
                        childImageSharp {
                          fixed(width: 750, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    field_image_1 {
                      localFile {
                        childImageSharp {
                          fixed(width: 500, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return productTransformers.transformProductData(productData);
};

export default ProductQuery;
