import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import * as utils from '@utils';
import {SidebarImage, SidebarImageItem} from "@content/types/sidebarImage";
import SidebarImageItemComponent from "@components/common/SidebarImageItems/SidebarImageItemComponent";

const SidebarImageTitle = styled.h3`
  text-align: center;
`;

const SidebarImageItemsWrapper = styled.div`
  ${respondFrom(
    breakpoints.lg,
    css`
      margin: 115px auto -60px; 
    `
  )}
`;

const SidebarImageItems = ({ title, items }: SidebarImage) => (
  <>
    <SidebarImageItemsWrapper>
      <SidebarImageTitle>{utils.SafeHtml(title)}</SidebarImageTitle>
      {items.length && items.map((sidebarImageItem: SidebarImageItem, index: number) => {
        return <SidebarImageItemComponent
          highlighted={sidebarImageItem.highlighted}
          image={sidebarImageItem.image}
          imageAlt={sidebarImageItem.imageAlt}
          imageSide={sidebarImageItem.imageSide}
          text={sidebarImageItem.text}
          key={index}
        />;
      })}
    </SidebarImageItemsWrapper>
  </>
);

export default SidebarImageItems;
