import React from 'react';
import {SidebarImageItem} from "@content/types/sidebarImage";
import Image from "gatsby-image";
import styled from "@emotion/styled";
import {breakpoints, colors, css, dimensions, respondFrom} from "@styles";
import * as utils from '@utils';

const SidebarImageWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  flex-direction: column;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 12px 72px;
      flex-direction: row;
    `
  )}

  &.image-right-side {
    ${respondFrom(
      breakpoints.lg,
      css`
        flex-direction: row-reverse;
      `
    )}
  }
`;

const BoxText = styled.div`
  max-width: 430px;
  padding: 30px 60px;
  z-index: 1;
  background-color: ${colors.ui.whisper};
  margin: -40px 0 0 0;

    ${respondFrom(
      breakpoints.lg,
      css`
        margin: 0 0 0 -40px;
      `
    )}

    &.image-right-side {
    ${respondFrom(
      breakpoints.lg,
      css`
        margin: 0 -40px 0 0;
      `
    )}  
  }
`;

const Highlighted = styled.div`
  color: ${colors.green.default};
  font-size: ${dimensions.fontSize.regular}px;
  font-weight: 700;
  text-transform: uppercase;
    margin-bottom: 8px;
`;

const SidebarImageItemComponent = ({ highlighted, image, imageAlt, imageSide, text }: SidebarImageItem) => {
  const imageClassName =  imageSide === 'left_side' ? 'image-left-side' : 'image-right-side';

  return (
    <>
      <SidebarImageWrapper className={imageClassName}>
        {image && <Image {...image.childImageSharp} alt={imageAlt} />}
        <BoxText className={imageClassName}>
          {highlighted && <Highlighted>{utils.SafeHtml(highlighted)}</Highlighted>}
          {text && utils.SafeHtml(text)}
        </BoxText>
      </SidebarImageWrapper>
    </>
  );
}

export default SidebarImageItemComponent;
