import React from 'react';

import Page from '@components/layout/Page';
import Container from '@components/layout/Container';
import ProductsHero from '@components/common/PageHero';
import ProductsStorytellStart from '@components/products/ProductsStorytellStart';
import ProductFunctions from '@components/products/ProductsFunctions';
import ProductsFunctionsRow from '@components/products/ProductsFunctionsRow';
import ProductsFunctionLogin from '@components/products/ProductsFunctionLogin';
import ProductsFunctionManagement from '@components/products/ProductsFunctionManagement';
import ProductsComparison from '@components/products/ProductsComparison';
import ProductsSupport from '@components/products/ProductsSupport';
import BannerSavings from '@components/common/BannerSavings';
import ProductsFundings from '@components/products/ProductsFundings';
import ProductsBranches from '@components/products/ProductsBranches';
import { ProductPage } from '@content/types/product';
import styled from '@emotion/styled';
import AnimationProductHero from '@animations/AnimationProductHero';
import { breakpoints, respondFrom } from '@styles';
import { css } from '@emotion/core';
import * as utils from "@utils";
import {SidebarImage} from "@content/types/sidebarImage";
import SidebarImageItems from "@components/common/SidebarImageItems";

const StyledAnimationProductHero = styled(AnimationProductHero)`
  display: none;

  ${respondFrom(
    breakpoints.md,
    css`
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(29%, 22%);
    `
  )}
`;

const ProductIntroTitle = styled.div`
  text-align: left;
  margin-bottom: 25px;

  ${respondFrom(
    breakpoints.lg,
      css`
        text-align: center;
        margin: 115px auto -60px;
        max-width: 500px;
      `
  )}
`;

export interface ProductProps extends ProductPage {
  langcode: string;
  urls: Array<string>;
}

export default (props: ProductProps) => {
  return (
    <Page>
      <ProductsHero
        animation={<StyledAnimationProductHero sectionVisible={true} />}
        settings={props.hero}
        langcode={props.langcode}
        urls={props.urls}
      />

      <Container>
        {props.productIntroTitle && <ProductIntroTitle>{utils.SafeHtml(props.productIntroTitle)}</ProductIntroTitle>}
        {props.sidebarImageItems.length && props.sidebarImageItems.map((sidebarImage: SidebarImage, index: number) => {
          return <SidebarImageItems title={sidebarImage.title} items={sidebarImage.items} key={index}  />;
        })}
        <ProductsStorytellStart settings={props.iconsRow1} />
        <ProductFunctions settings={props.bullets} storytellEnd={true} langcode={props.langcode} />
        <ProductsFunctionsRow settings={props.iconsRow2} />
        <ProductsFunctionLogin
          settings={props.mosaic1}
          langcode={props.langcode}
          urls={props.urls}
        />
        <ProductsFunctionManagement
          settings={props.mosaic2}
          langcode={props.langcode}
          urls={props.urls}
        />
      </Container>

      <ProductsComparison settings={props} langcode={props.langcode} urls={props.urls} />

      <Container>
        <ProductsSupport settings={props.iconsRow3} />
      </Container>

      <BannerSavings
        title={props.bannerSaveings.title}
        text={props.bannerSaveings.paragraph}
        buttonText={props.bannerSaveings.button}
        langcode={props.langcode}
        urls={props.urls}
      />

      <Container>
        <ProductsFundings settings={props.fundings} langcode={props.langcode} urls={props.urls} />
        <ProductsBranches langcode={props.langcode} urls={props.urls} />
      </Container>
    </Page>
  );
};
